import { useEffect, useState } from "react";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import SyncDisableIcon from "src/assets/SyncDisable";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "src/assets/Close";
import CheckIcon from "src/assets/Check";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import {
  getDeskeraRedirectUrl,
  setDisconnectedDeskeraAccount,
  getDeskeraUserInfo,
  setDisconnectedShopifyAccount,
  getDeskeraBooksAccounts,
  getDeskeraBooksWarehouses,
  setShopifyConfig,
  setShopifyKeys,
  enableShopifySync,
  disableShopifySync,
  getDeskeraBooksTaxes,
  getDeskeraProductUom,
  saveShopifyLocationMappings,
  getShopifyLocationMappings,
  saveShopifyTransactionSync,
  getDeskeraBooksDiscounts,
  saveShopifyDiscountConfigs,
  disableShopifyDiscountConfigs,
  enabledNegativeInventory,
  savePaymentMethodNewConfig,
  getDeskeraTenantDetails,
} from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import { setDeskeraInfo } from "src/redux/actions/auth";
import {
  loadConfig,
  setConfig,
  loadRefunds,
  loadAccounts,
  setLocations,
  loadLocations,
  setPaymentMethodConfig,
  setDiscountConfig,
  loadPaymentAccountConfig,
  loadDiscountConfig,
  loadShopifyContactCustomerType,
  setShopifyContactCustomerType,
  updateShopfiyContactCompanyType,
  setRefunds,
  updateShopfiyRefunds,
} from "src/redux/actions/shopify";
import SyncIcon from "src/assets/Sync";
import { addToast } from "src/redux/actions/toasts";
import { useHistory } from "react-router";
import { DKButton, DKCalendar, DKIcons, getDateAsString } from "deskera-ui-library";
import moment from "moment";
import { yearFirstDateFormat } from "src/utils/Constants";

const SYNC_START_DATE_ALERT = "Sync Start Date should be after the beginning of the books";
const AUTO_BUILD_BOM_ERROR = "Please select only one BOM auto-build option";
const SyncNameTypeOptions = [
  { value: "CONTACT", label: "Contact" },
  { value: "COMPANY", label: "Company" },
];

const PaymentTypeOptions = [
  { value: "CHEQUE", label: "Cheque" },
  { value: "CASH", label: "Cash" },
  { value: "BANK_TRANSFER", label: "Bank Transfer" },
  { value: "CARD", label: "Card" },
  { value: "OTHERS", label: "Others" },
];

function Config() {
  const tenantInfo = useSelector((state) => state.auth.user);
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [disconnectWarningShop, setDisconnectWarningShop] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [niWarning, setNiWarning] = useState(false);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [disconnectButtonShop, setDisconnectButtonShop] = useState(true);
  const accounts = useSelector((state) => state.shopify.accounts);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const shopifyConfig = useSelector((state) => state.shopify.config);
  const shopifyRefunds = useSelector((state) => state?.shopify.refunds.data) || [];
  console.log(shopifyRefunds);
  console.log("shopifyRefunds");
  const shopifyContactCustomerType = useSelector((state) => state.shopify.contactCustomerType);
  console.log(shopifyContactCustomerType);
  const [formErrors, setFormErrors] = useState({});
  const [refundErrors, setRefundErrors] = useState({});
  const [shopifyKeysFormErrors, setshopifyKeysFormErrors] = useState({});
  const [configOpen, setConfigOpen] = useState(false);
  const [shopifyKeysInfo, setShopifyKeysInfo] = useState({});
  const [apiKeysError, setApiKeysError] = useState(false);
  const [apiKeyErrorMsg, setSetApiKeyErrorMsg] = useState("");
  const history = useHistory();
  const [accountsConfigured, setAccountsConfigured] = useState(false);
  const [saveShopLoading, setSaveShopLoading] = useState(false);
  const [disconnectingAccounts, setDisconnectingAccounts] = useState({});

  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [deskeraTaxes, setDeskeraTaxes] = useState([]);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const locationMappings = useSelector((state) => state.shopify.locations);
  const [locationMappingsConfigured, setLocationMappingsConfigured] = useState(false);
  const [saveLocationsLoading, setSaveLocationsLoading] = useState(false);
  const [configMappings, setConfigMappings] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [country, setCountry] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [checked, setChecked] = useState(shopifyConfig.data.syncFromAllWarehouses);
  const [syncInOutTransactions, setSyncInOutTransactions] = useState(
    shopifyConfig.data.syncInOutTransactions
  );
  const [syncProductDeletionToDeskera, setSyncProductDeletionToDeskera] = useState(
    shopifyConfig.data.syncProductDeletionToDeskera
  );
  const [twoWaySync, setTwoWaySync] = useState(shopifyConfig.data.twoWaySync);
  const [mapDiscountEnabled, setMapDiscountEnabled] = useState(
    shopifyConfig.data.mapDiscountEnabled
  );
  const [saveTransactionSyncLoading, setSaveTransactionSyncLoading] = useState(false);
  const useDocumentCodeFromShopifyOptions = [
    { value: true, label: "YES" },
    { value: false, label: "NO" },
  ];
  const [configPaymentAccountMappings, setConfigPaymentAccountMappings] = useState([]);
  const [configDiscountMappings, setConfigDiscountMappings] = useState([]);
  const paymentConfig = useSelector((state) => state.shopify.paymentConfig);
  const discountCodes = useSelector((state) => state.shopify.discountCodes);
  const [paymentAccountMappingConfigured, setpaymentAccountMappingConfigured] = useState(false);
  const [saveDiscountConfigLoading, setSaveDiscountConfigLoading] = useState(false);
  const gstTreatmentIndiaOptions = [
    { value: "REGISTERED_BUSINESS_REGULAR", label: "Registered Business - Regular" },
    { value: "REGISTERED_BUSINESS_COMPOSITION", label: "Registered Business - Composition" },
    { value: "UNREGISTERED_BUSINESS", label: "Unregistered Business" },
    { value: "CUSTOMER", label: "Consumer" },
    { value: "OVERSEAS", label: "Overseas" },
    { value: "SPECIAL_ECONOMIC_ZONE", label: "Special Economic Zone" },
    { value: "DEEMED_EXPORT", label: "Deemed Export" },
  ];
  const [savePaymentAccountConfigLoading, setSavePaymentAccountConfigLoading] = useState(false);

  const [showSyncStartDatePopup, setSyncStartDatePopup] = useState(false);
  const [syncStartDate, setSyncStartDate] = useState(new Date());
  const [actionLoading, setActionLoading] = useState({
    contactCompanyType: false,
    refunds: false,
  });
  useEffect(() => {
    initialiseConfigMappings();
    handleLocationMappingsConfigLoad();
    initialiseLocationOptions();
  }, [locationMappings, deskeraWarehouses]);

  useEffect(() => {
    setTwoWaySync(shopifyConfig.data.twoWaySync);
    setMapDiscountEnabled(shopifyConfig.data.mapDiscountEnabled);
    setSyncInOutTransactions(shopifyConfig.data.syncInOutTransactions);
    setSyncProductDeletionToDeskera(shopifyConfig.data.syncProductDeletionToDeskera);
    initialiseDocumentType();
    updateSyncStartDate();

    if (shopifyConfig && shopifyConfig.data)   {
        if (shopifyConfig.data?.autoBuildBomProductOnOrderSync === true && shopifyConfig.data?.autoBuildBomProductOnFulfillment === true)    {
            setFormErrors({
              ...formErrors,
              autoBuildBomProductOnOrderSync: AUTO_BUILD_BOM_ERROR,
              autoBuildBomProductOnFulfillment: AUTO_BUILD_BOM_ERROR,
            });
        }   else    {
            setFormErrors({
              ...formErrors,
              autoBuildBomProductOnOrderSync: false,
              autoBuildBomProductOnFulfillment: false,
            });
        }
    }
  }, [shopifyConfig]);

  const [paymentMapping, setPaymentMappingOptions] = useState([]);
  const [discountMapping, setDiscountMappingOptions] = useState([]);

  useEffect(() => {
    initialiseConfigPaymentAccountMappings();
  }, [paymentConfig, paymentMapping]);
  useEffect(() => {
    initialiseConfigDiscountMappings();
  }, [discountCodes, discountMapping]);

  function initialiseConfigPaymentAccountMappings() {
    const configPaymentAccountMapping = [];

    for (let i = 0; i < paymentConfig.data.length; i++) {
      let payment = paymentConfig.data[i];
      let Name = payment.gateway_name;
      let options = paymentMapping;
      let configPayment = {
        gateway_name: Name,
        account_code: payment.account_code,
        options: options,
      };
      configPaymentAccountMapping.push(configPayment);
    }
    setConfigPaymentAccountMappings(configPaymentAccountMapping);
  }

  function initialiseConfigDiscountMappings() {
    const configDiscountMapping = [];

    for (let i = 0; i < discountCodes.data.length; i++) {
      let discount = discountCodes.data[i];
      let Name = discount.code;
      let options = discountMapping;
      let configPayment = {
        name: Name,
        deskeraAccountCode: discount.deskeraAccountCode,
        options: options,
        id: discount.id,
      };
      configDiscountMapping.push(configPayment);
    }
    setConfigDiscountMappings(configDiscountMapping);
  }

  async function initialiseLocationOptions() {
    const locations = [];
    if (locationMappings.data.length > 0) {
      locationMappings.data.forEach((location) => {
        locations.push({
          value: location.shopifyLocationId,
          label: location.locationName,
        });
      });
    }
    setLocationOptions(locations);
  }

  async function initialiseConfigMappings() {
    const mapConf = [];
    for (let i = 0; i < locationMappings.data.length; i++) {
      let lmap = locationMappings.data[i];
      let configMap = {
        name: lmap.locationName,
        id: lmap.id,
        deskeraWarehouseId: lmap.deskeraWarehouseId,
        options: deskeraWarehouses,
      };
      mapConf.push(configMap);
    }
    setConfigMappings(mapConf);
  }

  async function handleLocationMappingsConfigLoad() {
    setLocationMappingsConfigured(true);
    configMappings.forEach((cMap) => {
      if (!cMap.deskeraWarehouseId) {
        setLocationMappingsConfigured(false);
        return;
      }
    });
    if (checked) {
      if (!shopifyConfig.defaultShopifyLocationId) {
        setLocationMappingsConfigured(false);
      }
    }
  }
  async function initialiseDocumentType() {
    const documentTypes = [];
    documentTypes.push({ value: "SALES_INVOICE", label: "Sales Invoice" });
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      if (tenantDetails !== undefined && tenantDetails.hasOwnProperty("additionalSettings")) {
        const additionalSettings = tenantDetails.additionalSettings;
        if (additionalSettings.hasOwnProperty("ENABLE_SO") && additionalSettings.ENABLE_SO) {
          documentTypes.push({ value: "SALES_ORDER", label: "Sales Order" });
        }
      }
      setCountry(tenantDetails.country);
      console.log(tenantDetails);
    } catch (err) {
      return;
    }
    setDocumentType(documentTypes);
  }

  function updateSyncStartDate() {
    const bbDate = tenantInfo.bookBeginningStartDate;
    const syncStartDate = shopifyConfig.data.syncStartDate;
    let date = "";
    if (syncStartDate === null || syncStartDate === undefined) {
      date = moment(bbDate, yearFirstDateFormat).toDate();
    } else {
      date = moment(syncStartDate, yearFirstDateFormat).toDate();
    }
    console.log(date);
    setSyncStartDate(date);
  }

  async function setLocationMapping(e, i) {
    const lmaps = locationMappings.data;
    const conf = lmaps.splice(i, 1)[0];
    conf.deskeraWarehouseId = e.value;
    lmaps.splice(i, 0, conf);
    console.log(lmaps);

    dispatch(setLocations(lmaps));
  }

  async function saveLocationMappings(e) {
    e.preventDefault();
    setSaveLocationsLoading(true);
    try {
      const configResp = await saveShopifyLocationMappings(locationMappings.data);
      dispatch(setLocations(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Location Mappings updated successfully.",
        })
      );
    } catch {
      setError("Failed to save Location Mappings");
    }

    const newConfig = { ...shopifyConfig.data };
    if (checked) {
      if (!newConfig.defaultShopifyLocationId || !newConfig.defaultShopifyLocationName) {
        setFormErrors({ ...formErrors, defaultShopifyLocation: "Select a valid account" });
        return;
      }
    }

    try {
      const resp = await setShopifyConfig(newConfig);
      dispatch(setConfig(resp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Locations config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveLocationsLoading(false);
    dispatch(loadLocations());
  }

  async function saveTransactionSync(e) {
    e.preventDefault();
    setSaveTransactionSyncLoading(true);
    const body = {
      twoWaySync: shopifyConfig.data.twoWaySync ? shopifyConfig.data.twoWaySync : false,
      syncInOutTransactions: shopifyConfig.data.syncInOutTransactions
        ? shopifyConfig.data.syncInOutTransactions
        : false,
      syncProductDeletionToDeskera: shopifyConfig.data.syncProductDeletionToDeskera
        ? shopifyConfig.data.syncProductDeletionToDeskera
        : false,
    };
    try {
      const configResp = await saveShopifyTransactionSync(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync setting updated successfully.",
        })
      );
    } catch {
      setError("Failed to save Transaction sync setting.");
    }

    const newConfig = { ...shopifyConfig.data };

    try {
      const resp = await setShopifyConfig(newConfig);
      dispatch(setConfig(resp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveTransactionSyncLoading(false);
  }

  async function disableDiscountConfigs(e) {
    e.preventDefault();
    setSaveDiscountConfigLoading(true);
    try {
      const configResp = await disableShopifyDiscountConfigs();
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Discount Config setting disabled successfully.",
        })
      );
    } catch {
      setError("Failed to disable Discount Config setting.");
    }

    const newConfig = { ...shopifyConfig.data };

    try {
      const resp = await setShopifyConfig(newConfig);
      dispatch(setConfig(resp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Discount Config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveDiscountConfigLoading(false);
  }

  const useBooksTaxRateOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const [bankOptions, setBankOptions] = useState([]);

  async function handleEnableNegativeInventory() {
    try {
      const config = await enabledNegativeInventory();
      dispatch(loadConfig());
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Negative Inventory Enabled Successfully.",
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Failed to enabled negative inventory",
        })
      );
    }
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      const bankoptions = [];
      const paymentMapping = [];
      accounts.forEach((account) => {
        if (account.accountGroup === "Bank") {
          bankoptions.push({ value: account.code, label: account.name });
        }
        if (account.accountGroup === "Bank" || account.accountGroup === "Cash") {
          paymentMapping.push({ value: account.code, label: account.name });
        }
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
      setBankOptions(bankoptions);
      setPaymentMappingOptions(paymentMapping);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function getDeskeraDiscounts() {
    try {
      const discounts = await getDeskeraBooksDiscounts();
      const discountMapping = [];
      discounts.forEach((additionalCharge) => {
        if (additionalCharge.isDiscount !== undefined && additionalCharge.isDiscount) {
          discountMapping.push({
            id: additionalCharge.id,
            value: additionalCharge.expenseAccountCode,
            label: additionalCharge.name,
          });
        }
      });
      setDiscountMappingOptions(discountMapping);
    } catch {
      setError("Failed to load Deskera Books Discounts");
    }
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }

  async function getBooksTaxes() {
    try {
      const taxes = await getDeskeraBooksTaxes();
      const taxesOptions = [];
      taxes.forEach((tax) => {
        taxesOptions.push({ value: tax.code, label: tax.name });
      });
      setDeskeraTaxes(taxesOptions);
    } catch {
      setError("Failed to load Deskera Books Taxes");
    }
  }

  function setPaymentAccount(e, i) {
    const payments = paymentConfig.data;
    const conf = payments.splice(i, 1)[0];

    conf.account_code = e.value;
    conf.account_name = e.label;
    payments.splice(i, 0, conf);

    dispatch(setPaymentMethodConfig(payments));
  }
  function setDiscountAccount(e, i) {
    const discounts = discountCodes.data;
    const conf = discounts.splice(i, 1)[0];

    conf.deskeraAccountCode = e.value;
    conf.accountName = e.label;
    conf.id = e.id;
    discounts.splice(i, 0, conf);

    dispatch(setDiscountConfig(discounts));
  }
  async function savePaymentAccountConfig(e) {
    e.preventDefault();
    setSavePaymentAccountConfigLoading(true);
    try {
      const configResp = await savePaymentMethodNewConfig(paymentConfig.data);
      dispatch(setPaymentMethodConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Payment Account updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSavePaymentAccountConfigLoading(false);
    dispatch(loadPaymentAccountConfig());
  }

  async function saveDiscountConfig(e) {
    e.preventDefault();
    setSaveDiscountConfigLoading(true);
    try {
      const configResp = await saveShopifyDiscountConfigs(discountCodes.data);
      dispatch(setDiscountConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Discount Mapping updated successfully.",
        })
      );
    } catch {
      setError("Failed to set discount config");
    }
    setSaveDiscountConfigLoading(false);
    dispatch(loadDiscountConfig());
  }

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadConfig());
    dispatch(loadLocations());
    dispatch(loadShopifyContactCustomerType());
    getBooksAccounts();
    getDeskeraDiscounts();
    getBooksWarehouses();
    getBooksTaxes();
    getBooksProductUom();
    dispatch(loadPaymentAccountConfig());
    dispatch(loadDiscountConfig());
    dispatch(loadRefunds());
  }, []);

  useEffect(() => {
    handleConfigLoad();
  }, [shopifyConfig, bankOptions, paymentMapping]);

  const handleSyncTypeChange = (storeId, newValue) => {
    dispatch(
      setShopifyContactCustomerType(
        shopifyContactCustomerType.data.map((store) =>
          store.id === storeId ? { ...store, syncNameType: newValue } : store
        )
      )
    );
  };

  const handleRefundAccountChange = (storeId, key, newValue) => {
    console.log(newValue);
    const newRefunds = shopifyRefunds.map((store) => {
      if (store.storeId === storeId) {
        store.refundConfig[key] = newValue;
      }
      if(refundErrors){
        refundErrors[storeId] = { ...refundErrors[storeId], [key]: "" };
        setRefundErrors(refundErrors);
      }
      return store;
    });


    dispatch(setRefunds(newRefunds));
  };

  function validateRefundConfig(stores) {
    const errors = {};

    stores.forEach((store) => {
      const { storeId, refundConfig } = store;
      const fields = ["refundAccount", "refundPayFromAccount", "refundPaymentMethod"];
      fields.forEach((field) => {
        if (!refundConfig[field]) {
          errors[storeId] = { ...errors[storeId], [field]: "Required" };
        }
      });
    });
    console.log(errors);
    return errors;
  }

  console.log(formErrors);

  const handleRefundsSave = async () => {
    try {
      setActionLoading((prev) => ({ ...prev, refunds: true }));
      let letRefundsFormErrors = {};
      letRefundsFormErrors = validateRefundConfig(shopifyRefunds);
      setRefundErrors(letRefundsFormErrors);
      if (Object.keys(letRefundsFormErrors).length > 0) {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: "Please fill all the required fields",
          })
        );
        return;
      }
      await dispatch(updateShopfiyRefunds(shopifyRefunds));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Refunds Config Updated.",
        })
      );
    } catch (e) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Refunds Config Failed to Update",
        })
      );
    } finally {
      setActionLoading((prev) => ({ ...prev, refunds: false }));
    }
  };

  const handleSave = async () => {
    try {
      setActionLoading((prev) => ({ ...prev, contactCompanyType: true }));
      await dispatch(updateShopfiyContactCompanyType(shopifyContactCustomerType.data));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Shopify Config Updated.",
        })
      );
    } catch (e) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Shopify Config Failed to Update",
        })
      );
    } finally {
      setActionLoading((prev) => ({ ...prev, contactCompanyType: false }));
    }
  };

  function handleConfigLoad() {
    setAccountsConfigured(true);
    if (!shopifyConfig.data.productPurchaseAccountCode) {
      setAccountsConfigured(false);
      return;
    } //1

    if (!shopifyConfig.data.productSalesReturnAccountCode) {
      setAccountsConfigured(false);
      return;
    } //2

    if (!shopifyConfig.data.productSalesAccountCode) {
      setAccountsConfigured(false);
      return;
    } //3

    if (!shopifyConfig.data.productPurchaseReturnAccountCode) {
      setAccountsConfigured(false);
      return;
    } //4

    if (!shopifyConfig.data.contactPayableAccountCode) {
      setAccountsConfigured(false);
      return;
    } //5

    if (!shopifyConfig.data.contactReceivableAccountCode) {
      setAccountsConfigured(false);
      return;
    } //6

    if (!shopifyConfig.data.productPurchaseTaxCode) {
      setAccountsConfigured(false);
      return;
    } //7

    if (!shopifyConfig.data.productSalesTaxCode) {
      setAccountsConfigured(false);
      return;
    } //8

    if (!shopifyConfig.data.productCostOfGoodsSoldAccountCode) {
      setAccountsConfigured(false);
      return;
    } //9

    if (!shopifyConfig.data.productInventoryAccountCode) {
      setAccountsConfigured(false);
      return;
    } //10

    if (!shopifyConfig.data.productStockAdjustmentAccountCode) {
      setAccountsConfigured(false);
      return;
    } //11

    if (!shopifyConfig.data.productWarehouseCode) {
      setAccountsConfigured(false);
      return;
    } //12
    if (
      !shopifyConfig.data.paymentDepositAccountCode ||
      !shopifyConfig.data.paymentDepositAccountName
    ) {
      setAccountsConfigured(false);
      return;
    } //13
    if (
      !shopifyConfig.data.useDocumentCodeFromShopifyForOrders &&
      shopifyConfig.data.useDocumentCodeFromShopifyForOrders !== false
    ) {
      console.log(shopifyConfig.data.useDocumentCodeFromShopifyForOrders);
      setAccountsConfigured(false);
      return;
    } //14
    if (
      !shopifyConfig.data.useDocumentCodeFromShopifyForProducts &&
      shopifyConfig.data.useDocumentCodeFromShopifyForProducts !== false
    ) {
      console.log(shopifyConfig.data.useDocumentCodeFromShopifyForProducts);
      setAccountsConfigured(false);
      return;
    } //16
  }

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      const disconnectResp = await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectShopifyAccount(e, accountId) {
    e.preventDefault();
    setDisconnectingAccounts((prev) => ({ ...prev, [accountId]: true }));
    try {
      const disconnectResp = await setDisconnectedShopifyAccount(accountId);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Shopify store disconnected succesfully.",
        })
      );
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Shopify store");
    } finally {
      setDisconnectingAccounts((prev) => ({ ...prev, [accountId]: false }));
    }
  }

  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    const newConfig = {
      ...shopifyConfig.data,
      syncEnabled: syncStatus,
      disableSyncReason: syncStatus ? "" : shopifyConfig.data.disableSyncReason,
    };
    try {
      if (syncStatus) {
        setSaveSyncLoading(true);
        const enableResp = await enableShopifySync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been Enabled.",
          })
        );
        setSaveSyncLoading(false);
      } else {
        setSaveSyncLoading(true);
        const disableResp = await disableShopifySync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been disabled.",
          })
        );
        setSaveSyncLoading(false);
      }
      // const configResp = await setShopifyConfig(newConfig);
      dispatch(setConfig(newConfig));
      setSyncButton(true);
      setSyncWarning(false);
    } catch {
      setError("Failed to update config");
      setSaveSyncLoading(false);
    }
  }

  async function saveAccountConfigs(e) {
    e.preventDefault();
    const configErrors = {};
    if (!shopifyConfig.data.productPurchaseAccountCode) {
      configErrors.productPurchaseAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productPurchaseAccountCode: "Select a valid account" });
    } //1

    if (!shopifyConfig.data.productSalesReturnAccountCode) {
      configErrors.productSalesReturnAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productSalesReturnAccountCode: "Select a valid account" });
    } //2

    if (!shopifyConfig.data.productSalesAccountCode) {
      configErrors.productSalesAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productSalesAccountCode: "Select a valid account" });
    } //3

    if (!shopifyConfig.data.productPurchaseReturnAccountCode) {
      configErrors.productPurchaseReturnAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productPurchaseReturnAccountCode: "Select a valid account" });
    } //4

    if (!shopifyConfig.data.contactPayableAccountCode) {
      configErrors.contactPayableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactPayableAccountCode: "Select a valid account" });
    } //5

    if (!shopifyConfig.data.contactReceivableAccountCode) {
      configErrors.contactReceivableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactReceivableAccountCode: "Select a valid account" });
    } //6

    if (!shopifyConfig.data.productCostOfGoodsSoldAccountCode) {
      configErrors.productCostOfGoodsSoldAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productCostOfGoodsSoldAccountCode: "Select a valid account" });
    } //9

    if (!shopifyConfig.data.productInventoryAccountCode) {
      configErrors.productInventoryAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productInventoryAccountCode: "Select a valid account" });
    } //10

    if (!shopifyConfig.data.productStockAdjustmentAccountCode) {
      configErrors.productStockAdjustmentAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productStockAdjustmentAccountCode: "Select a valid account" });
    } //11

    if (!shopifyConfig.data.productWarehouseCode) {
      configErrors.productWarehouseCode = "Select a valid account";
      setFormErrors({ ...formErrors, productWarehouseCode: "Select a valid account" });
    } //12

    if (
      !shopifyConfig.data.paymentDepositAccountCode ||
      !shopifyConfig.data.paymentDepositAccountName
    ) {
      configErrors.paymentDepositAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, paymentDepositAccountCode: "Select a valid account" });
    } //13

    if (
      !shopifyConfig.data.useDocumentCodeFromShopifyForOrders &&
      shopifyConfig.data.useDocumentCodeFromShopifyForOrders !== false
    ) {
      configErrors.useDocumentCodeFromShopifyForOrders = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useDocumentCodeFromShopifyForOrders: "Select a valid option",
      });
    } //14
    if (
      !shopifyConfig.data.useDocumentCodeFromShopifyForProducts &&
      shopifyConfig.data.useDocumentCodeFromShopifyForProducts !== false
    ) {
      configErrors.useDocumentCodeFromShopifyForProducts = "Select a valid option";
      setFormErrors({
        ...formErrors,
        useDocumentCodeFromShopifyForProducts: "Select a valid option",
      });
    } //15

    if (shopifyConfig.data.hideProducts === null || shopifyConfig.data.hideProducts === undefined) {
      dispatch(
        setConfig({
          ...shopifyConfig.data,
          hideProducts: false
        })
      );
    }

    if (!isValidSyncStartDate(syncStartDate)) {
      configErrors.syncStartDate = SYNC_START_DATE_ALERT;
      setFormErrors({
        ...formErrors,
        syncStartDate: SYNC_START_DATE_ALERT,
      });
    }

    if (shopifyConfig && shopifyConfig.data)   {
        if (shopifyConfig.data?.autoBuildBomProductOnOrderSync === true && shopifyConfig.data?.autoBuildBomProductOnFulfillment === true)    {
            configErrors.autoBuildBomProductOnOrderSync = AUTO_BUILD_BOM_ERROR;
            configErrors.autoBuildBomProductOnFulfillment = AUTO_BUILD_BOM_ERROR;
            setFormErrors({
              ...formErrors,
              autoBuildBomProductOnOrderSync: AUTO_BUILD_BOM_ERROR,
              autoBuildBomProductOnFulfillment: AUTO_BUILD_BOM_ERROR,
            });
        }
    }

    if (Object.keys(configErrors).length > 0) {
      setFormErrors(configErrors);
      const ERRORS_ALERTS = "Some account mappings are missing or invalid";
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: ERRORS_ALERTS,
        })
      );
      return;
    } else {
      setFormErrors({});
    }

    const newConfig = { 
      ...shopifyConfig.data,
      hideProducts: shopifyConfig?.data?.hideProducts === true
    };

    setSaveConfigLoading(true);
    try {
      const configResp = await setShopifyConfig(newConfig);
      dispatch(setConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success", 
          message: "Accounts config updated successfully."
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveConfigLoading(false);
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title="Disconnect"
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  const getCalendarView = (selectedDate, onSelect, toggleView) => {
    return (
      <div
        className="position-absolute shadow-m bg-white z-10 top-12 right-20 deskera-datepicker"
        style={{
          zIndex: 1000,
        }}
      >
        <DKCalendar
          className="w-500"
          color={"rgb(33, 107, 165)"}
          selectedDate={selectedDate}
          onSelectDate={(newDate) => {
            onSelect(newDate);
            toggleView(false);
          }}
          onClose={() => setTimeout(() => toggleView(false))}
        />
      </div>
    );
  };

  function connectModalShop() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <p className="text-muted">Link your Shopify stores.</p>
          <div className="d-flex flex-row-reverse mb-2">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setConfigOpen(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <PlusLogo />
              </span>
              <span>{accounts.data.length ? "Add Another Store" : "Connect Shopify Store"}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  async function disconnectShopifyStore(e, accountId) {
    await handleDisconnectShopifyAccount(e, accountId);
    setDisconnectWarningShop(false);
  }

  function connectedModalShop() {
    return (
      <>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            <p className="text-muted">Your Shopify account is linked.</p>
            <table className="table m-0 table-no-padding">
              {!disconnectWarningShop && (
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
              )}
              <tbody>
                {accounts.data.map((account, index) => (
                  <div>
                    {!disconnectWarningShop && (
                      <tr>
                        <td className="align-middle" style={{ width: "10%" }}>
                          <p className="m-0">
                            <b>{account.name}</b>
                          </p>
                          <span class="d-inline-block text-truncate">
                            <small>{account.id}</small>
                          </span>
                        </td>
                        <td className="align-middle text-right">
                          {/* <DKButton
                            className="bg-danger text-white"
                            onClick={(e) => setDisconnectWarningShop(true)}
                            title="Disconnect"
                          ></DKButton> */}
                          <button
                            // style={{ marginRight: "10px" }}
                            onClick={(e) => setDisconnectWarningShop(true)}
                            className="btn border-radius-m p-v-s text-white bg-danger"
                          >
                            <span className="svg-icon svg-baseline mr-2 svg-white">
                              <DeleteLogo />
                            </span>
                            <span>Disconnect</span>
                          </button>
                        </td>
                      </tr>
                    )}

                    <CSSTransition
                      in={disconnectWarningShop}
                      timeout={100}
                      classNames="scale-opacity"
                      unmountOnExit
                      onEnter={() => setDisconnectButtonShop(false)}
                      onExited={() => setDisconnectButtonShop(true)}
                    >
                      <Alert className="m-0 flex-fill" type="warning">
                        <p className="m-0">Disconnect Shopify Store?</p>
                        <div className="d-flex flex-row-reverse">
                          <button
                            className="btn dk-btn font-weight-bold text-muted px-3"
                            onClick={(e) => disconnectShopifyStore(e, account.id)}
                          >
                            <span className="svg-icon svg-baseline svg-warning">
                              <CheckIcon />
                            </span>
                          </button>
                          <button
                            className="btn dk-btn mr-2 px-3"
                            onClick={(e) => setDisconnectWarningShop(false)}
                          >
                            <span className="svg-icon svg-baseline svg-warning">
                              <CloseIcon />
                            </span>
                          </button>
                        </div>
                      </Alert>
                    </CSSTransition>
                  </div>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Products and Customers from your Shopify store
          will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            // <DKButton
            //   className="bg-danger text-white"
            //   title="Disable Sync"
            //   style={{ marginRight: "15px" }}
            //   onClick={(e) => setSyncWarning(true)}
            // ></DKButton>
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => setSyncWarning(true)}
              // style={{ marginRight: "20px" }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is Disabled for your account. Orders, Products and Customers from your Shopify store
          are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {/* <DKButton
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
            className="bg-success text-white"
            title="Enable Sync"
            style={{ marginRight: "15px" }}
          ></DKButton> */}
          <button
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
            className="btn border-radius-m p-v-s text-white bg-success"
            // style={{ marginRight: "20px" }}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncDisableIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  function negativeInventoryDisabledModal() {
    return (
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <p className="text-muted flex-fill">
            Negative Inventory is Disabled for your account. Please note that once <br></br>enabled,
            this cannot be disabled.
          </p>
          <span className="flex-fill d-flex flex-row-reverse" style={{ minWidth: "5%" }}>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
                checked={shopifyConfig.data.allowNegativeInventory}
                onClick={() => {
                  setNiWarning(true);
                }}
                disabled={shopifyConfig.data.allowNegativeInventory}
              />
              <label class="custom-control-label" for="customSwitch1"></label>
            </div>
          </span>
        </div>

        <div className="d-flex flex-row-reverse">
          <CSSTransition in={niWarning} timeout={100} classNames="scale-opacity" unmountOnExit>
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Once enabled, this cannot be changed. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEnableNegativeInventory();
                    setNiWarning(false);
                  }}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setNiWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function negativeInventoryEnabledModal() {
    return (
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <p className="text-muted flex-fill">
            Negative Inventory is enabled for your account. This cannot be changed.
          </p>
          <span className="flex-fill d-flex flex-row-reverse" style={{ minWidth: "5%" }}>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
                checked={true}
                disabled={true}
              />
              <label class="custom-control-label" for="customSwitch1"></label>
            </div>
          </span>
        </div>
      </div>
    );
  }

  async function handleAddShopifyStore(e) {
    e.preventDefault();
    let isError = false;
    if (!shopifyKeysInfo.shopifyShopName) {
      setshopifyKeysFormErrors({ ...shopifyKeysFormErrors, shopNameError: "Enter a Shop Name" });
      return;
    }
    if (!shopifyKeysInfo.shopifyShopUrl) {
      setshopifyKeysFormErrors({
        ...shopifyKeysFormErrors,
        shopUrlError: "Enter shop url (https://example.myshopify.com)",
      });
      return;
    }
    if (!shopifyKeysInfo.shopifyApiKey) {
      setshopifyKeysFormErrors({ ...shopifyKeysFormErrors, apiKeyError: "API Key is required" });
      return;
    }
    if (!shopifyKeysInfo.shopifyApiPassword) {
      setshopifyKeysFormErrors({
        ...shopifyKeysFormErrors,
        apiPasswordError: "API Password is required",
      });
      return;
    }
    setSaveShopLoading(true);
    try {
      const connResp = await setShopifyKeys(shopifyKeysInfo);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Shopify Store connected successfully.",
        })
      );
      dispatch(loadAccounts());
      setConfigOpen(false);
    } catch (err) {
      const error = err?.response?.data ?? "Failed to add Shopify Store";
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: error,
        })
      );
      setError(error);
      setApiKeysError(true);
      setSetApiKeyErrorMsg(error);
    }
    setSaveShopLoading(false);
    dispatch(loadLocations());
  }

  const isValidSyncStartDate = (date) => {
    const bbDate = moment(tenantInfo.bookBeginningStartDate, yearFirstDateFormat).toDate();
    if (date < bbDate) {
      return false;
    } else {
      return true;
    }
  };

  function shopifyConnect() {
    console.log(shopifyKeysFormErrors);
    return (
      <div className="d-flex">
        <div
          className="vw-100 vh-100 d-flex justify-content-center align-items-center"
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div className="card dk-card dk-card-shadow mb-4 d-flex" style={{ width: "70%" }}>
            <div className="card-body d-flex">
              <div className="card-body" width={{ width: "10%" }}>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Store Name</b>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Store name"
                    onChange={(e) => {
                      setShopifyKeysInfo({ ...shopifyKeysInfo, shopifyShopName: e.target.value });
                      setApiKeysError(false);
                      setSetApiKeyErrorMsg("");
                      setshopifyKeysFormErrors({
                        ...shopifyKeysFormErrors,
                        shopNameError: "",
                      });
                    }}
                  />
                  {shopifyKeysFormErrors.shopNameError && (
                    <div className="text-danger ">
                      <small>{shopifyKeysFormErrors.shopNameError}</small>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Store URL</b>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="https://example-store.myshopify.com"
                    onChange={(e) => {
                      setShopifyKeysInfo({ ...shopifyKeysInfo, shopifyShopUrl: e.target.value });
                      setApiKeysError(false);
                      setSetApiKeyErrorMsg("");
                      setshopifyKeysFormErrors({
                        ...shopifyKeysFormErrors,
                        shopUrlError: "",
                      });
                    }}
                  />
                  {shopifyKeysFormErrors.shopUrlError && (
                    <div className="text-danger">
                      <small>{shopifyKeysFormErrors.shopUrlError}</small>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Shopify API Key</b>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter API Key"
                    onChange={(e) => {
                      setShopifyKeysInfo({ ...shopifyKeysInfo, shopifyApiKey: e.target.value });
                      setApiKeysError(false);
                      setSetApiKeyErrorMsg("");
                      setshopifyKeysFormErrors({
                        ...shopifyKeysFormErrors,
                        apiKeyError: "",
                      });
                    }}
                  />
                  {shopifyKeysFormErrors.apiKeyError && (
                    <div className="text-danger ">
                      <small>{shopifyKeysFormErrors.apiKeyError}</small>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Shopify API Password</b>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter API Password"
                    onChange={(e) => {
                      setShopifyKeysInfo({
                        ...shopifyKeysInfo,
                        shopifyApiPassword: e.target.value,
                      });
                      setApiKeysError(false);
                      setSetApiKeyErrorMsg("");
                      setshopifyKeysFormErrors({
                        ...shopifyKeysFormErrors,
                        apiPasswordError: "",
                      });
                    }}
                  />
                  {shopifyKeysFormErrors.apiPasswordError && (
                    <div className="text-danger ">
                      <small>{shopifyKeysFormErrors.apiPasswordError}</small>
                    </div>
                  )}
                </div>
                {apiKeysError && (
                  <div className="text-danger ">
                    <small>{apiKeyErrorMsg}</small>
                  </div>
                )}
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn border-radius-m p-v-s text-white bg-success"
                    onClick={(e) => handleAddShopifyStore(e)}
                    disabled={saveShopLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-white">
                      {saveShopLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                  <button
                    className="btn border-radius-m p-v-s text-white bg-danger"
                    style={{ marginRight: "2%" }}
                    onClick={(e) => {
                      setConfigOpen(false);
                      setApiKeysError(false);
                      setSetApiKeyErrorMsg("");
                      setShopifyKeysInfo({});
                    }}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                      <CloseIcon />
                    </span>
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
              <div className="card-body text-muted" style={{ width: "25%" }}>
                To connect Shopify to Deskera, you will need to provide API key and API password
                which can be generated in Shopify admin panel. Here is a step-by-step guidance that
                will help you to retrieve Shopify credentials by your own:
                <ol>
                  <li>Log in your store's admin area and go to Apps Menu.</li>
                  <li>Press Manage private app. Then, click the Create private apps button.</li>
                  <li>Enter the Name of the app and provide your Contact email.</li>
                  <li>
                    Then, you need to grant the Private app with permissions to enable data
                    interaction with your store. To do this, click on all drop-down menus and change
                    permission status from “No access” to “Read and Write”.
                  </li>
                </ol>
                After that, click Save and the button Create app. API key and API password will
                appear right after you save the app.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 w-auto">
      {configOpen && shopifyConnect()}
      <h3 className="mb-4 text-bold">Account Setup</h3>

      {shopifyConfig?.data?.disableSyncReason && (
        <div className="mb-4">
          <Alert type="warning">{shopifyConfig?.data?.disableSyncReason}</Alert>
        </div>
      )}

      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Shopify Stores</h5>
          </div>
          {/* {deskeraInfo.accountConnected && accounts.data.length
            ? connectedModalShop()
            : connectModalShop()} */}
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <p className="text-muted">Link your Shopify stores.</p>
              <div className="d-flex flex-row-reverse mb-2">
                <button
                  className="btn dk-btn font-weight-bold text-success px-3 border border-success"
                  onClick={(e) => setConfigOpen(true)}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    <PlusLogo />
                  </span>
                  <span>
                    {accounts.data.length ? "Add Another Store" : "Connect Shopify Store"}
                  </span>
                </button>
              </div>
              {accounts.data.length > 0 && (
                <table className="table m-0 table-no-padding">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.data.map((account, index) => (
                      <tr>
                        <td className="align-middle">
                          <p className="m-0">
                            <b>{account.name}</b>
                          </p>
                          <span>
                            <small>{account.id}</small>
                          </span>
                        </td>
                        <td className="align-middle text-right" style={{ width: 180 }}>
                          <button
                            className="btn text-danger px-4 dk-btn border border-danger"
                            onClick={(e) => handleDisconnectShopifyAccount(e, account.id)}
                          >
                            {disconnectingAccounts[account.id] ? (
                              <>
                                <div
                                  class="spinner-border text-danger spinner-border-sm"
                                  role="status"
                                ></div>{" "}
                              </>
                            ) : (
                              <span className="svg-icon svg-baseline mr-2 svg-danger">
                                <DeleteLogo />
                              </span>
                            )}
                            <span>Disconnect</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Books Accounts</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow zIndex-1-important">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Shopify products can be synced with Deskera
                  Books.
                </Alert>
              )}
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Purchase Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({ ...shopifyConfig.data, productPurchaseAccountCode: e.value })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.productPurchaseAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productPurchaseAccountCode && (
                  <div className="text-danger">
                    <small> {formErrors.productPurchaseAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Sales Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...shopifyConfig.data, productSalesAccountCode: e.value }))
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.productSalesAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productSalesAccountCode && (
                  <div className="text-danger">
                    <small> {formErrors.productSalesAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Purchase Return Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        productPurchaseReturnAccountCode: e.value,
                      })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.productPurchaseReturnAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productPurchaseReturnAccountCode && (
                  <div className="text-danger">
                    <small> {formErrors.productPurchaseReturnAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Sales Return Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({ ...shopifyConfig.data, productSalesReturnAccountCode: e.value })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.productSalesReturnAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productSalesReturnAccountCode && (
                  <div className="text-danger">
                    <small>{formErrors.productSalesReturnAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Contact Payable Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({ ...shopifyConfig.data, contactPayableAccountCode: e.value })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.contactPayableAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.contactPayableAccountCode && (
                  <div className="text-danger">
                    <small>{formErrors.contactPayableAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Contact Receivable Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({ ...shopifyConfig.data, contactReceivableAccountCode: e.value })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.contactReceivableAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.contactReceivableAccountCode && (
                  <div className="text-danger">
                    <small>{formErrors.contactReceivableAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Purchase Tax Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraTaxes}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...shopifyConfig.data, productPurchaseTaxCode: e.value }))
                  }
                  value={deskeraTaxes.filter(
                    (obj) => obj.value === shopifyConfig.data.productPurchaseTaxCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productPurchaseTaxCode && (
                  <div className="text-danger">
                    <small>{formErrors.productPurchaseTaxCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Sales Tax Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraTaxes}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...shopifyConfig.data, productSalesTaxCode: e.value }))
                  }
                  value={deskeraTaxes.filter(
                    (obj) => obj.value === shopifyConfig.data.productSalesTaxCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productSalesTaxCode && (
                  <div className="text-danger">
                    <small>{formErrors.productSalesTaxCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Select Zero Rated Tax Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraTaxes}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...shopifyConfig.data, zeroRatedTaxCode: e.value }))
                  }
                  value={deskeraTaxes.filter(
                    (obj) => obj.value === shopifyConfig.data.zeroRatedTaxCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Cost Of Goods Sold Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        productCostOfGoodsSoldAccountCode: e.value,
                      })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.productCostOfGoodsSoldAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productCostOfGoodsSoldAccountCode && (
                  <div className="text-danger">
                    <small>{formErrors.productCostOfGoodsSoldAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Inventory Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({ ...shopifyConfig.data, productInventoryAccountCode: e.value })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.productInventoryAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productInventoryAccountCode && (
                  <div className="text-danger">
                    <small>{formErrors.productInventoryAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Stock Adjustment Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        productStockAdjustmentAccountCode: e.value,
                      })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.productStockAdjustmentAccountCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productStockAdjustmentAccountCode && (
                  <div className="text-danger">
                    <small>{formErrors.productStockAdjustmentAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Warehouse Code</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraWarehouses}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        productWarehouseCode: e.value,
                      })
                    )
                  }
                  value={deskeraWarehouses.filter(
                    (obj) => obj.value === shopifyConfig.data.productWarehouseCode
                  )}
                  // menuPortalTarget={document.body}
                  // styles={{
                  //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  // }}
                />
                {formErrors.productWarehouseCode && (
                  <div className="text-danger">
                    <small>{formErrors.productWarehouseCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Unit of Measure</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraProductUom}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        productUom: e.value,
                      })
                    )
                  }
                  value={deskeraProductUom.filter(
                    (obj) => obj.value === shopifyConfig.data.productUom
                  )}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Ignore Shopify taxes and use Tax Rates from Books</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={useBooksTaxRateOptions}
                  isSearchable={false}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        useBooksTaxRates: e.value,
                      })
                    )
                  }
                  value={useBooksTaxRateOptions.filter(
                    (obj) => obj.value === shopifyConfig.data.useBooksTaxRates
                  )}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Payment Deposit Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={bankOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        paymentDepositAccountCode: e.value,
                        paymentDepositAccountName: e.label,
                      })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === shopifyConfig.data.paymentDepositAccountCode
                  )}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {formErrors.paymentDepositAccountCode && (
                  <div className="text-danger">
                    <small> {formErrors.paymentDepositAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Use Document Code from Shopify for Orders</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useDocumentCodeFromShopifyOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        useDocumentCodeFromShopifyForOrders: e.value,
                      })
                    )
                  }
                  value={useDocumentCodeFromShopifyOptions.filter(
                    (obj) => obj.value === shopifyConfig.data.useDocumentCodeFromShopifyForOrders
                  )}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {formErrors.useDocumentCodeFromShopifyForOrders && (
                  <div className="text-danger">
                    <small> {formErrors.useDocumentCodeFromShopifyForOrders}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Use Document Code from Shopify For Products</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useDocumentCodeFromShopifyOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        useDocumentCodeFromShopifyForProducts: e.value,
                      })
                    )
                  }
                  value={useDocumentCodeFromShopifyOptions.filter(
                    (obj) => obj.value === shopifyConfig.data.useDocumentCodeFromShopifyForProducts
                  )}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {formErrors.useDocumentCodeFromShopifyForProducts && (
                  <div className="text-danger">
                    <small> {formErrors.useDocumentCodeFromShopifyForProducts}</small>
                  </div>
                )}
              </div>

              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Select Document To create in Deskera Books for Shopify Orders</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={documentType}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        booksDocumentTypeForShopifyOrders: e.value,
                      })
                    )
                  }
                  value={documentType.filter(
                    (obj) => obj.value === shopifyConfig.data.booksDocumentTypeForShopifyOrders
                  )}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {formErrors.booksDocumentTypeForShopifyOrders && (
                  <div className="text-danger">
                    <small> {formErrors.booksDocumentTypeForShopifyOrders}</small>
                  </div>
                )}
              </div>

              {country == "IN" && (
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Select GST Treatment for Deskera Contact</b>
                  </div>
                  <Select
                    placeholder="Select an Option"
                    theme={customSelectTheme}
                    options={gstTreatmentIndiaOptions}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch(
                        setConfig({
                          ...shopifyConfig.data,
                          gstTreatmentIndia: e.value,
                        })
                      )
                    }
                    value={gstTreatmentIndiaOptions.filter(
                      (obj) => obj.value === shopifyConfig.data.gstTreatmentIndia
                    )}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {formErrors.gstTreatmentIndia && (
                    <div className="text-danger">
                      <small> {formErrors.gstTreatmentIndia}</small>
                    </div>
                  )}
                </div>
              )}
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Auto-Build BOM Product upon sync of Fulfillment</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useDocumentCodeFromShopifyOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        autoBuildBomProductOnFulfillment: e.value,
                      })
                    )
                  }
                  value={useDocumentCodeFromShopifyOptions.filter((obj) => {
                    if (shopifyConfig.data?.autoBuildBomProductOnFulfillment == null) {
                      return obj.value === false;
                    }
                    return obj.value === shopifyConfig.data.autoBuildBomProductOnFulfillment;
                  })}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {formErrors.autoBuildBomProductOnFulfillment && (
                  <div className="text-danger">
                    <small> {formErrors.autoBuildBomProductOnFulfillment}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Auto Build BOM upon Order Sync</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useDocumentCodeFromShopifyOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        autoBuildBomProductOnOrderSync: e.value,
                      })
                    )
                  }
                  value={useDocumentCodeFromShopifyOptions.filter((obj) => {
                    if (shopifyConfig.data?.autoBuildBomProductOnOrderSync == null) {
                      return obj.value === false;
                    }
                    return obj.value === shopifyConfig.data.autoBuildBomProductOnOrderSync;
                  })}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {formErrors.autoBuildBomProductOnOrderSync && (
                  <div className="text-danger">
                    <small> {formErrors.autoBuildBomProductOnFulfillment}</small>
                  </div>
                )}
              </div>

              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Hide Sync Products</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useDocumentCodeFromShopifyOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...shopifyConfig.data,
                        hideProducts: e.value,
                      })
                    )
                  }
                  value={useDocumentCodeFromShopifyOptions.filter((obj) => {
                    if (!shopifyConfig.data?.hideProducts) {
                      return obj.value === false;
                    }
                    return obj.value === shopifyConfig.data.hideProducts;
                  })}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                {formErrors.hideProducts && (
                  <div className="text-danger">
                    <small> {formErrors.hideProducts}</small>
                  </div>
                )}
              </div>

              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Sync Start Date</b>
                </div>
                <button
                  className="align-items-center dk-button-hover border-m bg-white position-relative  mr-3 px-2 input-style"
                  onClick={() => setSyncStartDatePopup(true)}
                  style={{ height: "38px", width: "100%", display: "flex" }}
                >
                  <img
                    src={DKIcons.data_type.ic_date}
                    alt=""
                    className="ic-r ic-s"
                    style={{
                      objectFit: "scale-down",
                      marginBottom: "2px",
                    }}
                  />
                  <span style={{ fontSize: "13px", color: "rgb(45, 43, 37)", marginLeft: "8px" }}>
                    {getDateAsString(syncStartDate, "dd-MM-yyyy")}
                  </span>
                </button>
                {showSyncStartDatePopup &&
                  getCalendarView(
                    syncStartDate,
                    (date) => {
                      if (isValidSyncStartDate(date)) {
                        setSyncStartDate(date);
                        setFormErrors({
                          ...formErrors,
                          syncStartDate: "",
                        });
                        let formattedDate = moment(date).format(yearFirstDateFormat);
                        dispatch(
                          setConfig({ ...shopifyConfig.data, syncStartDate: formattedDate })
                        );
                        setSyncStartDatePopup(false);
                      } else {
                        setFormErrors({
                          ...formErrors,
                          syncStartDate: SYNC_START_DATE_ALERT,
                        });
                        setSyncStartDate(date);
                      }
                    },
                    setSyncStartDatePopup
                  )}
                {formErrors.syncStartDate && (
                  <div className="text-danger">
                    <small> {formErrors.syncStartDate}</small>
                  </div>
                )}
              </div>

              <div className="d-flex flex-row-reverse">
                {/* <DKButton
                  onClick={saveAccountConfigs}
                  disabled={saveConfigLoading}
                  className="bg-success text-white"
                  title="Save"
                  style={{ marginRight: "15px" }}
                ></DKButton> */}
                <button
                  onClick={saveAccountConfigs}
                  disabled={saveConfigLoading}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  // style={{ marginRight: "20px" }}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/*Data Sync Modal*/}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {shopifyConfig.data.syncEnabled ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}

      {/* Negative Inventory Modal */}
      {shopifyConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Negative Inventory</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              {shopifyConfig.data.allowNegativeInventory
                ? negativeInventoryEnabledModal()
                : negativeInventoryDisabledModal()}
            </div>
          </>
        )}
      {/* Sync Name Type {`Contact / Company` */}

      {shopifyConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Sync Name Type {`Contact / Company`}</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                <div className="form-group">
                  {shopifyContactCustomerType.data.map((currentStore) => (
                    <div key={currentStore.id} className="mb-3">
                      <div className="d-flex justify-content-between">
                        <p
                          className="m-0 text-muted font-weight-bold"
                          style={{ fontSize: "1rem", color: "#4B5563" }}
                        >
                          {currentStore.shopName}
                        </p>
                      </div>
                      <div className="mt-2">
                        <Select
                          placeholder="Select an Option"
                          theme={customSelectTheme}
                          options={SyncNameTypeOptions}
                          isSearchable={true}
                          menuPlacement="auto"
                          onChange={(e) => handleSyncTypeChange(currentStore.id, e.value)}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          value={SyncNameTypeOptions.find(
                            (option) => option.value === currentStore.syncNameType
                          )}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="d-flex flex-row-reverse mt-2">
                    <button
                      className="btn dk-btn font-weight-bold text-success border border-success px-4"
                      onClick={handleSave}
                      disabled={actionLoading.contactCompanyType}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-success">
                        {actionLoading.contactCompanyType ? (
                          <div
                            className="spinner-border text-success spinner-border-sm"
                            role="status"
                          />
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Refunds Config</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <div className="form-group">
                {shopifyRefunds.map((refund, index) => {
                  return (
                    <>
                      <div key={refund.storeId} className="mb-3">
                        <div className="d-flex justify-content-between">
                          <p
                            className="m-0 text-muted font-weight-bold"
                            style={{ fontSize: "1rem", color: "#4B5563" }}
                          >
                            {refund.storeName}
                          </p>
                        </div>

                        <div className="px-2 my-2">
                          <div className="text-muted mb-2" aria="label">
                            <b>Select Chart Of Account</b>
                          </div>
                          <Select
                            placeholder="Select an Option"
                            theme={customSelectTheme}
                            options={deskeraAccounts}
                            isSearchable={true}
                            menuPlacement="auto"
                            onChange={(e) => {
                              handleRefundAccountChange(refund.storeId, "refundAccount", e.value);
                              handleRefundAccountChange(
                                refund.storeId,
                                "refundAccountName",
                                e?.label
                              );
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={deskeraAccounts.find(
                              (option) => option.value === refund.refundConfig.refundAccount
                            )}
                          />
                          <p className="text-danger">
                            {refundErrors[refund.storeId] &&
                              refundErrors[refund.storeId]?.refundAccount && (
                                <small> {refundErrors[refund.storeId]?.refundAccount}</small>
                              )}
                          </p>
                          <div className="text-muted my-2" aria="label">
                            <b>Refunds Pay From Account</b>
                          </div>
                          <Select
                            placeholder="Select an Option"
                            theme={customSelectTheme}
                            options={deskeraAccounts}
                            isSearchable={true}
                            menuPlacement="auto"
                            onChange={(e) => {
                              handleRefundAccountChange(
                                refund.storeId,
                                "refundPayFromAccount",
                                e.value
                              );
                              handleRefundAccountChange(
                                refund.storeId,
                                "refundPayFromAccountName",
                                e.label
                              );
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={deskeraAccounts.find(
                              (option) => option.value === refund.refundConfig.refundPayFromAccount
                            )}
                          />
                          <p className="text-danger">
                            {refundErrors[refund.storeId] &&
                              refundErrors[refund.storeId]?.refundPayFromAccount && (
                                <small> {refundErrors[refund.storeId]?.refundPayFromAccount}</small>
                              )}
                          </p>
                          <div className="text-muted my-2" aria="label">
                            <b>Payment Method</b>
                          </div>
                          <Select
                            placeholder="Select an Option"
                            theme={customSelectTheme}
                            options={PaymentTypeOptions}
                            isSearchable={true}
                            menuPlacement="auto"
                            onChange={(e) => {
                              handleRefundAccountChange(
                                refund.storeId,
                                "refundPaymentMethod",
                                e.value
                              );
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={PaymentTypeOptions.find(
                              (option) => option.value === refund.refundConfig.refundPaymentMethod
                            )}
                          />

                          <p className="text-danger">
                            {refundErrors[refund.storeId] &&
                              refundErrors[refund.storeId]?.refundPaymentMethod && (
                                <small> {refundErrors[refund.storeId]?.refundPaymentMethod}</small>
                              )}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="d-flex flex-row-reverse mt-2">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={handleRefundsSave}
                    disabled={actionLoading.refunds}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {actionLoading.refunds ? (
                        <div
                          className="spinner-border text-success spinner-border-sm"
                          role="status"
                        />
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Discount Modal */}
      {shopifyConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Discount Mappings</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                {!discountMapping.length > 0 && (
                  <Alert type="primary">Discounts must be created in Deskera Books.</Alert>
                )}
                {
                  <>
                    <div className="form-group">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          defaultChecked={mapDiscountEnabled}
                          onChange={() => {
                            if (mapDiscountEnabled) {
                              dispatch(
                                setConfig({
                                  ...shopifyConfig.data,
                                  mapDiscountEnabled: !mapDiscountEnabled,
                                })
                              );
                            } else {
                              dispatch(
                                setConfig({
                                  ...shopifyConfig.data,
                                  mapDiscountEnabled: !mapDiscountEnabled,
                                })
                              );
                            }
                            setMapDiscountEnabled(!mapDiscountEnabled);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox2">
                          Enable Discount Mappings
                        </label>
                      </div>
                    </div>
                  </>
                }
                {mapDiscountEnabled && (
                  <>
                    <div className="card w-500 mb-4 dk-card dk-card-shadow">
                      <div className="card-body">
                        {configDiscountMappings.length === 0 && (
                          <span className="mt-2 text-muted">No Discounts found at shopify.</span>
                        )}
                        {configDiscountMappings.length > 0 &&
                          configDiscountMappings.map((configDiscountMapping, i) => (
                            <div className="form-group">
                              <div className="text-muted mb-2" aria="label">
                                <b>{configDiscountMapping.name}</b>
                              </div>
                              <Select
                                placeholder="Select a Discount"
                                theme={customSelectTheme}
                                options={configDiscountMapping.options}
                                isSearchable={true}
                                menuPlacement="auto"
                                onChange={(e) => {
                                  setDiscountAccount(e, i);
                                }}
                                value={configDiscountMapping.options.filter(
                                  (obj) => obj.id === configDiscountMapping.id
                                )}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                              />
                              {formErrors[configDiscountMapping.deskeraAccountCode] && (
                                <div>{formErrors[configDiscountMapping.deskeraAccountCode]}</div>
                              )}
                            </div>
                          ))}
                        {configDiscountMappings.length > 0 && (
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn dk-btn font-weight-bold text-success border border-success px-4"
                              onClick={saveDiscountConfig}
                              disabled={saveDiscountConfigLoading}
                            >
                              <span className="svg-icon svg-baseline mr-2 svg-success">
                                {saveDiscountConfigLoading ? (
                                  <div
                                    class="spinner-border text-success spinner-border-sm"
                                    role="status"
                                  ></div>
                                ) : (
                                  <FloppyDiskIcon />
                                )}
                              </span>
                              <span>Save</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {!mapDiscountEnabled && (
                  <div className="d-flex flex-row-reverse">
                    <button
                      onClick={(e) => disableDiscountConfigs(e)}
                      disabled={saveDiscountConfigLoading}
                      className="btn border-radius-m p-v-s text-white bg-success"
                      // style={{ marginRight: "20px" }}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-white">
                        {saveDiscountConfigLoading ? (
                          <div
                            class="spinner-border text-white spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

      {/* Transaction Sync Modal*/}
      {shopifyConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Transaction Sync</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                {!accountsConfigured && (
                  <Alert type="primary">
                    Accounts must be configured before Shopify transactions can be synced with
                    Deskera Books.
                  </Alert>
                )}
                {
                  <>
                    <div className="form-group">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="transaction_sync_toggle"
                          defaultChecked={twoWaySync}
                          onChange={() => {
                            if (twoWaySync) {
                              dispatch(
                                setConfig({
                                  ...shopifyConfig.data,
                                  twoWaySync: !twoWaySync,
                                })
                              );
                              setTwoWaySync(!twoWaySync);
                            } else {
                              dispatch(
                                setConfig({
                                  ...shopifyConfig.data,
                                  twoWaySync: !twoWaySync,
                                  syncProductDeletionToDeskera: false,
                                  syncInOutTransactions: false,
                                })
                              );
                              setTwoWaySync(!twoWaySync);
                              setSyncProductDeletionToDeskera(false);
                              setSyncInOutTransactions(false);
                            }
                          }}
                        />
                        <label class="form-check-label text-muted" for="transaction_sync_toggle">
                          Enable two-way sync
                        </label>
                      </div>
                    </div>
                  </>
                }
                {twoWaySync && (
                  <>
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>Two-Way Sync Settings</b>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox3"
                          defaultChecked={syncProductDeletionToDeskera}
                          onChange={() => {
                            dispatch(
                              setConfig({
                                ...shopifyConfig.data,
                                syncProductDeletionToDeskera: !syncProductDeletionToDeskera,
                              })
                            );
                            setSyncProductDeletionToDeskera(!syncProductDeletionToDeskera);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox3">
                          Enable Sync of deletion of the product done on Shopify
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox4"
                          defaultChecked={syncInOutTransactions}
                          onChange={() => {
                            dispatch(
                              setConfig({
                                ...shopifyConfig.data,
                                syncInOutTransactions: !syncInOutTransactions,
                              })
                            );
                            setSyncInOutTransactions(!syncInOutTransactions);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox4">
                          Enable Stock IN/OUT Sync from Deskera to Shopify
                        </label>
                      </div>
                    </div>
                  </>
                )}
                {
                  <div className="d-flex flex-row-reverse">
                    <button
                      onClick={(e) => saveTransactionSync(e)}
                      disabled={saveTransactionSyncLoading}
                      className="btn border-radius-m p-v-s text-white bg-success"
                      // style={{ marginRight: "20px" }}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-white">
                        {saveTransactionSyncLoading ? (
                          <div
                            class="spinner-border text-white spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </>
        )}

      {/* Location Mapping Modal*/}
      {shopifyConfig.data.syncEnabled &&
        twoWaySync &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Location Mappings</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                {!locationMappingsConfigured && !locationMappings && (
                  <Alert type="primary">
                    Mappings must be configured before Shopify data can be synced with Deskera
                    Books.
                  </Alert>
                )}
                {locationMappings.data.length === 0 && (
                  <span className="mt-2 text-muted">No locations found.</span>
                )}
                {locationMappings.data.length > 0 &&
                  configMappings.map((configMapping, i) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configMapping.name}</b>
                      </div>
                      <Select
                        placeholder="Select a Warehouse"
                        theme={customSelectTheme}
                        options={configMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setLocationMapping(e, i);
                        }}
                        value={configMapping.options.filter(
                          (obj) => obj.value == configMapping.deskeraWarehouseId
                        )}
                        // menuPortalTarget={document.body}
                        // styles={{
                        //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        // }}
                      />
                      {formErrors[configMapping.deskeraWarehouseId] && (
                        <div>{formErrors[configMapping.deskeraWarehouseId]}</div>
                      )}
                    </div>
                  ))}
                {locationMappings.data.length > 0 && (
                  <>
                    <div className="form-group">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          defaultChecked={checked}
                          onChange={() => {
                            dispatch(
                              setConfig({
                                ...shopifyConfig.data,
                                syncFromAllWarehouses: !checked,
                              })
                            );
                            setChecked(!checked);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox1">
                          Sync books inventory transactions from all warehouses to Shopify
                        </label>
                      </div>
                    </div>
                    {checked && (
                      <div className="form-group">
                        <div className="text-muted mb-2" aria="label">
                          <b>Default Shopify Location</b>
                        </div>
                        <Select
                          placeholder="Select a Location"
                          theme={customSelectTheme}
                          options={locationOptions}
                          isSearchable={true}
                          menuPlacement="auto"
                          onChange={(e) =>
                            dispatch(
                              setConfig({
                                ...shopifyConfig.data,
                                defaultShopifyLocationId: e.value,
                                defaultShopifyLocationName: e.label,
                              })
                            )
                          }
                          value={locationOptions.filter(
                            (obj) => obj.value == shopifyConfig.data.defaultShopifyLocationId
                          )}
                          // menuPortalTarget={document.body}
                          // styles={{
                          //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          // }}
                        />
                        {formErrors.defaultShopifyLocation && (
                          <div>{formErrors.defaultShopifyLocation}</div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {locationMappings.data.length > 0 && (
                  <div className="d-flex flex-row-reverse">
                    {/* <DKButton
                      className="text-white bg-success"
                      onClick={saveLocationMappings}
                      disabled={saveLocationsLoading}
                      title="Save"
                      style={{ marginRight: "15px" }}
                    ></DKButton> */}
                    <button
                      onClick={saveLocationMappings}
                      disabled={saveLocationsLoading}
                      className="btn border-radius-m p-v-s text-white bg-success"
                      // style={{ marginRight: "20px" }}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-white">
                        {saveLocationsLoading ? (
                          <div
                            class="spinner-border text-white spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Payment Gateway Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!paymentAccountMappingConfigured && !paymentConfig && (
                <Alert type="primary">
                  Accounts must be configured before Payment Accounts can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configPaymentAccountMappings.length === 0 && (
                <span className="mt-2 text-muted">No payment method accounts found.</span>
              )}
              {configPaymentAccountMappings.length > 0 &&
                configPaymentAccountMappings.map((configPaymentAccountMapping, idx) => (
                  <div className="form-group">
                    <div className="text-muted mb-2" aria="label">
                      <b>{configPaymentAccountMapping.gateway_name}</b>
                    </div>
                    <Select
                      placeholder="Select an Account"
                      theme={customSelectTheme}
                      options={configPaymentAccountMapping.options}
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(e) => {
                        setPaymentAccount(e, idx);
                      }}
                      value={configPaymentAccountMapping.options.filter(
                        (obj) => obj.value === configPaymentAccountMapping.account_code
                      )}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {formErrors[configPaymentAccountMapping.account_code] && (
                      <div>{formErrors[configPaymentAccountMapping.account_code]}</div>
                    )}
                  </div>
                ))}
              {configPaymentAccountMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={savePaymentAccountConfig}
                    disabled={savePaymentAccountConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {savePaymentAccountConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Config;
